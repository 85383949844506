//Imports go here

function CustomerForm() {
    //Code goes here

    //What displays
    return (
        <>
        </>
    )
} // End CustomerForm()

export default CustomerForm;